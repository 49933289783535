// Import bootstrap framework
@import 'bootstrap-variables';
@import 'bootstrap-custom';


body {
  height: 100vh;
}

h1, .h1 {
  font-weight: 100;
}

.container-fluid {
  max-width: 1200px;
  overflow-wrap: break-word;
}

.btn {
  text-transform: uppercase;
  font-weight: bold;

  &.btn-lg {
    font-size: 17px;
    letter-spacing: 0.5px;
    font-weight: bold;
  }
}


// Typo Helper Classes
.h-secondary {
  color: $secondary;
}

.h-underlined {
  position: relative;

  &:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 150px;
  background: $primary;
  }
}

.bg-white {
  background-color: white;
}

.invers-block {
  color: white;
  background-color: $secondary;

  &.bg-image {
    background-image: url('../img/kroeber_sven.jpg');
    background-position: right center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .step-indicator {
    hr {
      border-color: white;
      opacity: 0.5;
    }

    .number-wrapper {
      border: 1px solid white;
    }
  }
}

@media only screen and (max-width: 982px) {
  .invers-block.bg-image {
    background-size: cover;
    background-position: center center;
  }
}
@media only screen and (max-width: 1152px) {
  .text-block {
    background-color: $secondary;
  }
}

.gradient-block {
	background-image: url('https://via.placeholder.com/150'), linear-gradient(40deg, #0F6FB1 0%, #135c8e 100%);
	background-position: right bottom;
	background-repeat: no-repeat;

	&.no-image {
	  background-image: linear-gradient(40deg, #0F6FB1 0%, #135c8e 100%);
	}
}

.has-shadow {
	box-shadow: $shadow-lg;
}

footer {
	.footer-wrapper {
	  background-color: $secondary;
	  color: white;
}

.sub-footer {
	  background-color: darken($secondary, 20%);
	  color: white;
	}
}

.navbar {
	min-height: 110px;
	transition: height 0.2s linear;

	.container-fluid {
	  @include media-breakpoint-down(sm) {
		flex-wrap: nowrap;
	  }
	}
}

.nav-item {
  position: relative;

  @include media-breakpoint-only(lg) {
    .nav-link:after {
      content: " ";
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $secondary;
      opacity: 0.5;
      transform: scale(0);
      transition: transform 0.2s linear;
    }
  }
  &:hover .nav-link:after {

    transform: scale(1);
  }
	.nav-link{
		color: $body-color;
		&:hover {
      color: $secondary;
		}
  }

  &.active .nav-link {
    color: $secondary;
    font-weight: bold;
  }
}

.navbar-brand {
	img {
	  transition: width 0.2s linear;
	}
}

.scale-anim {
	transition: transform 0.3s ease;
	&:hover {
		transform: scale(1.1);
	}
}

// Landingpage Content
.header-bg {
	background-image: url('../img/hero_bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}
.hero-unit {
	z-index: 1;
	position: relative;

.hero-img {
  transition: transform 4s ease;
}

.display-5 {
  font-size: calc(1.475rem + 1.6vw)
}

&:hover .hero-img {
  transform: scale(1.1);
}


&.hero-content {
  @include media-breakpoint-down(sm) {
    img {
      max-height: 300px;
    }
  }
  //   background-image: url('https://images.unsplash.com/photo-1451337516015-6b6e9a44a8a3?crop=entropy&fit=crop&fm=jpg&h=675&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=1375');
  //   background-size: cover;
  //   background-position: right top;
  //   background-repeat: no-repeat;
  }
}

@include media-breakpoint-only(xs) {
  .header-contact {
    width: 80vw;
  }
}

.testimonial {
  .bg-white {
    box-shadow: $shadow-lg;
    // background-image: url(../img/icons/testimonial_bg.png);
    background-repeat: no-repeat;
    background-position: 50px 120px;
  }

  img {
    position: relative;
    top: -40px;
    box-shadow: $shadow-lg;
  }
}

#faq-accordion {
	.card {
	  border: none;
	  margin-bottom: 0.5rem;

	  .toggle-icon img {
      transition: transform 0.2s linear;
      transform: rotate(-90deg);
	  }

	  .card-header:not(.collapsed){
      background-color: $primary;
      color: white;

      .toggle-icon img {
        transform: rotate(0);
      }

      img {
        filter: brightness(5);
      }
	  }

	  .card-header {
		border: none;
	  }
	}
}

.check-list {
	// list-style: none;
	padding-left: 25px;

	li {
	  position: relative;
	  margin-bottom: 10px;

	//   &:before {
	// 	content: ' ';
	// 	width: 20px;
	// 	height: 20px;
	// 	position: absolute;
	// 	top: 0;
	// 	left: -25px;
	// 	background-image: url(../img/icons/icn_checkbox.svg);
	//   }
	}
}

.step-indicator {
	.number-wrapper {
	  width: 50px;
	  height: 50px;
	  border: 1px solid $secondary;
	  border-radius: 50%;
	  opacity: 0.5;
	  font-size: 1.2rem;
	  text-align: center;
	  font-weight: bold;
	  line-height: 3rem;

	  &.active {
  		opacity: 1.0;
	  }
	}
}

.user-item {
	border-top: 1px solid $gray-400;
}

.error-marker {
	animation: pulse 2s ease infinite;
}

@keyframes pulse {
	0% {
	  transform: scale(0.8);
	}

	50% {
	  transform: scale(1);
	}

	100% {
	  transform: scale(0.8);
	}
}


section.pricing {
  background: $secondary;

}

.pricing .card {
  border: none;
  transition: all 0.2s;
  box-shadow: 0 18px 18px -10px rgba(0, 0, 0, 0.25)

}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 2rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

//Timeline
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

  .timeline:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 3px;
      background-color: #eeeeee;
      left: 50%;
      margin-left: -1.5px;
  }

  .timeline > li {
      margin-bottom: 20px;
      position: relative;
  }

      .timeline > li:before,
      .timeline > li:after {
          content: " ";
          display: table;
      }

      .timeline > li:after {
          clear: both;
      }

      .timeline > li:before,
      .timeline > li:after {
          content: " ";
          display: table;
      }

      .timeline > li:after {
          clear: both;
      }

      .timeline > li > .timeline-panel {
          width: 46%;
          float: left;
          background-color: white;
          border: 1px solid #d4d4d4;
          border-radius: 2px;
          padding: 20px;
          position: relative;
          -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
          box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      }

          .timeline > li > .timeline-panel:before {
              position: absolute;
              top: 26px;
              right: -15px;
              display: inline-block;
              border-top: 15px solid transparent;
              border-left: 15px solid #ccc;
              border-right: 0 solid #ccc;
              border-bottom: 15px solid transparent;
              content: " ";
          }

          .timeline > li > .timeline-panel:after {
              position: absolute;
              top: 27px;
              right: -14px;
              display: inline-block;
              border-top: 14px solid transparent;
              border-left: 14px solid #fff;
              border-right: 0 solid #fff;
              border-bottom: 14px solid transparent;
              content: " ";
          }

      .timeline > li > .timeline-badge {
          color: #fff;
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 1.4em;
          text-align: center;
          position: absolute;
          top: 16px;
          left: 50%;
          margin-left: -25px;
          background-color: #999999;
          z-index: 100;
          border-top-right-radius: 50%;
          border-top-left-radius: 50%;
          border-bottom-right-radius: 50%;
          border-bottom-left-radius: 50%;
      }

      .timeline > li.timeline-inverted > .timeline-panel {
          float: right;
      }

          .timeline > li.timeline-inverted > .timeline-panel:before {
              border-left-width: 0;
              border-right-width: 15px;
              left: -15px;
              right: auto;
          }

          .timeline > li.timeline-inverted > .timeline-panel:after {
              border-left-width: 0;
              border-right-width: 14px;
              left: -14px;
              right: auto;
          }

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

  .timeline-body > p + p {
      margin-top: 5px;
  }

@media (max-width: 767px) {
  ul.timeline:before {
      left: 40px;
  }

  ul.timeline > li > .timeline-panel {
      width: calc(100% - 90px);
      width: -moz-calc(100% - 90px);
      width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
      left: 15px;
      margin-left: 0;
      top: 16px;
  }

  ul.timeline > li > .timeline-panel {
      float: right;
  }

      ul.timeline > li > .timeline-panel:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
      }

      ul.timeline > li > .timeline-panel:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
      }
}



#cookiebanner {
  position: fixed;
  bottom: 0;
  background-color: #333;
  width: 100%;
  color: #fff;
  z-index: 999;
  #cookies-reject {
    color: #888;
  }
}
